import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Index',
        component:()=> import('../views/index.vue'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('../views/home.vue')
            },
            {
                path: '/browse',
                name: 'browse',
                component: () => import('../views/browse.vue'),
                redirect: '/browse/info',
                children: [
                    {
                        path: '/browse/info',
                        name: 'browseInfo',
                        component: () => import('../components/browse/info'),
                    }
                ]
            },
            {
                path: '/downloads',
                name: 'downloads',
                component: () => import('../views/downloads.vue')
            },
            {
                path: '/help',
                name: 'help',
                component: () => import('../views/help.vue')
            },
            {
                path: '/search',
                name: 'search',
                component: () => import('../views/search.vue')
            }
        ]
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router